import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import { OrgType, Dates, TechComponent } from '../components/experience'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'

class ExperiencePage extends React.Component {
  render() {
    const exp = this.props.data.mdx
    const meta = exp.frontmatter
    return (
      <Layout>
        <div className="flex min-h-screen flex-col">
          <header>
            <Link to="/" className="no-underline text-black">
              <h1 className="text-right leading-loose">&lang; Rainer Dreyer</h1>
            </Link>

            <h3>
              <Dates date={meta.date} dates={meta.dates} startDate={meta.startDate} />
            </h3>
            <h2 className="leading-none pb-4">
              <span className="text-grey-darker">{meta.role} </span>
              <OrgType type={meta.type} /> {meta.org}
            </h2>
          </header>

          <div className="experience-content flex-auto">
            <MDXRenderer>{exp.code.body}</MDXRenderer>
          </div>

          <footer className="py-4">
            {meta.tech && meta.tech.map(name => TechComponent(name, exp.fields.techPages))}
          </footer>
        </div>
      </Layout>
    )
  }
}
export default ExperiencePage

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      code {
        body
      }
      frontmatter {
        type
        dates {
          start
          end
          event
        }
        org
        detail
        tech
        role
      }
      fields {
        techPages
      }
    }
  }
`
